import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import LayoutIndex from "../../components/LayoutIndex"
import Seo from "../../components/seo"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import VideoGrid from "../../components/videoGrid"
import Presentation from "../../components/presentation"
import Contacts from "../../components/contacts"
import { useLocation } from "react-router-dom"
import { SocialIcon } from "react-social-icons"
// import React, { useEffect } from "react";
// import GApageView from "../common/GApageView";
import ReactGA from "react-ga"
import create3DContent from "../../three/create3DContent"
import "./aboutPresentation.scss"

export const query = graphql`
  query ($id: String) {
    about(id: { eq: $id }) {
      id
      title
      texte
      image
      tag
    }
    allFile(filter: { relativeDirectory: { eq: "presentation" } }) {
      nodes {
        relativeDirectory
        childImageSharp {
          gatsbyImageData
          parent {
            ... on File {
              id
              name
            }
          }
        }
      }
    }
  }
`

const IndexPage = props => {
  const titre = props.data.about.title
  const texte = props.data.about.texte
  const tag = props.data.about.tag

  let image = props.data.allFile.nodes.filter(
    el => el.childImageSharp.parent.name == props.data.about.image
  )[0].childImageSharp.gatsbyImageData
  image = getImage(image)

  const [isMobile, SetIsMobile] = useState(false)
  const logo = (
    <StaticImage
      src="../images/logo_white_2.png"
      width={300}
      quality={95}
      className="logoHeader"
      formats={["auto", "webp", "avif"]}
      alt="logo Picaro Video"
      style={{ marginBottom: `1.45rem` }}
    />
  )

  useEffect(() => {
    // create3DContent("threeCanvas")
    // googleAnalytics(location)
  }, [])

  return (
    <LayoutIndex>
      <Seo
        title="Home"
        lang={"fr"}
        description={
          "Société de production audiovisuelle sur Paris et dans toute la France. Création audiovisuelle, captation événementielle, motion design et vues aériennes drone."
        }
      />
      {/*
      <div className="backgroundAnimation"> 
         <div
          id="threeCanvas"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1000,
            height: "100%",
          }}
        >
          
        </div>
         
      </div>
      */}
      <div className="body">
        <div className="headerPresentation">
          <div className="headerWrapperinside">
            <div className="imageHeader">
              <GatsbyImage image={image} alt={titre} />
            </div>
            <div className="paragraph">

            <div className="titrePres">{titre}</div>
            <div></div>
            <div className="textPres">{texte}</div>
          </div>
          </div>
          <div className="videoGrid">
            <VideoGrid tags={[tag]}></VideoGrid>
          </div>
        </div>
      </div>
    </LayoutIndex>
  )
}

export default IndexPage
