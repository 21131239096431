import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from "./footer"
import "./layout.scss"
import { SocialIcon } from "react-social-icons"

const Layout = ({ children }) => {
  return (
    <>
      <div className="mainWrapperIndex">
        <div className="Header">
          <Header siteTitle="PICARO" />
        </div>
        <div className="content">{children}</div>
        <div  style={{ zIndex: 1000 }}><Footer /></div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
